import React from "react";
import { Helmet } from "react-helmet";
import { graphql, navigate } from "gatsby";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { v4 as uuidv4 } from "uuid";
import { isEmpty, reduce, find, isNull } from "lodash";

import {
  introStepAtom,
  selectedItemAtom,
  itemAtom,
  customStepAtom,
} from "../utils/atoms";
import Layout from "../layout";
import Input from "../components/Input/Input";
import CollarlabImg from "../components/CollarlabImg/CollarlabImg";

import { collarlabFromIdSelector } from "../utils/selectors";

// const stepOrder = ["intro-name", "intro-product", "intro-design"];
const stepOrder = ["intro-name", "intro-design"];

function SpidGrid({ data, location }) {
  const { shopifyId, title, description } = data.shopifyProduct;

  const [selectedItem, setSelectedItem] = useRecoilState(selectedItemAtom);
  const [item, setItem] = useRecoilState(itemAtom(selectedItem));
  const [introStep, setIntroStep] = useRecoilState(introStepAtom);
  const setCustomStep = useSetRecoilState(customStepAtom);
  const defaultCollarlab = useRecoilValue(
    collarlabFromIdSelector({ id: null })
  );
  if (!selectedItem || isNull(selectedItem)) {
    setSelectedItem(`cl-${uuidv4()}`);
  }

  const handleBack = () => {
    if (introStep === "intro-name") {
      navigate("/");
    } else {
      setIntroStep(stepOrder[stepOrder.indexOf(introStep) - 1]);
    }
  };

  const handleNext = () => {
    if (item.design === "DIY") {
      setCustomStep("custom-font");
      navigate(`/collarlab-custom`);
    } else {
      const selected = find(
        data.skus.edges,
        ({ node }) => node.design.name === item.design
      ).node.design;

      setItem({
        ...item,
        font: selected.font[0].data,
        typestyle: selected.typestyles[0].data,
        color: JSON.stringify({
          name: selected.color[0].data.name,
          primaryColor: selected.color[0].data.primary,
          secondaryColor: selected.color[0].data.secondary,
          backgroundColor: selected.color[0].data.background,
          iconColor: selected.color[0].data.icon,
          textColor: selected.color[0].data.text,
        }),
        icons: reduce(
          selected.icons,
          (arr, param) => {
            arr.push({ name: param.data.name, value: param.data.value, file: param.data.file.localFiles[0].publicURL })
            return arr;
          },
          []
        ),
      });
      setCustomStep("custom-size");
      navigate(`/collarlab-custom`);
    }
  };

  return (
    <Layout location={location} back={handleBack}>
      <>
        <Helmet title="Stuntpuppy" />

        <div className="flex flex-col h-screen justify-center items-center">
          {introStep === "intro-name" && (
            <div className="md:w-1/2 mb-10">
              <Input
                size="large"
                label="What's your pet's name?"
                name="petName"
                placeholder=""
                type="text"
                value={item.name}
                onChange={(e) => setItem({ ...item, name: e.target.value })}
              />
            </div>
          )}
          {introStep === "intro-product" && (
            <>
              <h2 className="text-xl font-medium my-2 text-center">
                Select Product
              </h2>
              <div className="flex">
                <label
                  className={`m-4 p-4 rounded ${
                    item.product === "collar"
                      ? "border border-sp-orange bg-sp-orange bg-opacity-20"
                      : "bg-white"
                  }`}
                  htmlFor="collar"
                >
                  <input
                    type="radio"
                    id="collar"
                    name="product"
                    value="collar"
                    className="opacity-0 absolute"
                    checked={item.product === "collar"}
                    onChange={(e) =>
                      setItem({ ...item, product: e.target.value })
                    }
                  />
                  Collar
                </label>

                <label
                  className={`m-4 p-4 rounded ${
                    item.product === "leash"
                      ? "border border-sp-orange bg-sp-orange bg-opacity-20"
                      : "bg-white"
                  }`}
                  htmlFor="leash"
                >
                  <input
                    type="radio"
                    id="leash"
                    name="product"
                    value="leash"
                    className="opacity-0 absolute"
                    checked={item.product === "leash"}
                    onChange={(e) =>
                      setItem({ ...item, product: e.target.value })
                    }
                  />
                  Leash
                </label>

                <label
                  className={`m-4 p-4 rounded ${
                    item.product === "bundle"
                      ? "border border-sp-orange bg-sp-orange bg-opacity-20"
                      : "bg-white"
                  }`}
                  htmlFor="bundle"
                >
                  <input
                    type="radio"
                    id="bundle"
                    name="product"
                    value="bundle"
                    className="opacity-0 absolute"
                    checked={item.product === "bundle"}
                    onChange={(e) =>
                      setItem({ ...item, product: e.target.value })
                    }
                  />
                  Bundle
                </label>
              </div>
            </>
          )}
          {introStep === "intro-design" && (
            <>
              <h2 className="text-3xl font-semibold mb-8 text-center">
                Start from scratch or pick a starting point
              </h2>
              <div className="flex w-full items-center overflow-x-auto h-3/6 px-6">
                <div className="h-full flex-shrink-0 flex flex-col items-center mx-32">
                  <div  className={`bg-white rounded-lg h-full px-10 mx-2 overflow-hidden border-4 ${
                    item.design === "DIY"
                      ? "border-sp-orange"
                      : ""
                  }`}>
                    <label
                      className="block h-full shadow-xl"
                      htmlFor="DIY"
                    >
                      <input
                        className="hidden"
                        type="radio"
                        id="DIY"
                        name="design"
                        value="DIY"
                        checked={item.design === "DIY"}
                        onChange={(e) =>
                          setItem({ ...item, design: e.target.value })
                        }
                      />
                      <CollarlabImg
                        flip
                        name={item.name || "Pet Name"}
                        sz={1}
                        type={defaultCollarlab.type}
                        backgroundColor={defaultCollarlab.backgroundColor}
                        primaryColor={defaultCollarlab.primaryColor}
                        secondaryColor={defaultCollarlab.secondaryColor}
                        iconColor={defaultCollarlab.iconColor}
                        icon={defaultCollarlab.icon}
                        placeholder=""
                        size="7rem"
                      />
                    </label>
                  </div>
                  <h3 className="uppercase font-bold text-sm mt-4">Design From Scratch</h3>
                </div>
                <div className="m-4 text-3xl mr-32 text-center">or</div>
                    {data.skus.edges.map(
                      ({
                        node: {
                          design: { name, color, font, typestyles, icons },
                        },
                      }) => (
                        <div key={name} className="h-full flex-shrink-0 flex flex-col items-center">
                          <div  className={`bg-white rounded-lg h-full px-10 mx-2 overflow-hidden border-4 ${
                              item.design === name
                              ? "border-sp-orange"
                              : ""
                          }`}>
                          <label
                            className="block h-full shadow-xl"
                            htmlFor={name}
                          >
                            <input
                              className="hidden"
                              type="radio"
                              id={name}
                              name="design"
                              value={name}
                              checked={item.design === name}
                              onChange={(e) =>
                                setItem({ ...item, design: e.target.value })
                              }
                            />
                            <CollarlabImg
                              flip
                              name={item.name || "Pet Name"}
                              sz={1}
                              type={`${font[0].data.value}-${typestyles[0].data.value}`}
                              backgroundColor={color[0].data.background}
                              primaryColor={color[0].data.primary}
                              secondaryColor={color[0].data.secondary}
                              iconColor={color[0].data.icon}
                              // type="collar"
                              icon={icons.map(
                                ({ data: { name, value, file } }) => ({ name, value, file: file.localFiles[0].publicURL })
                              )}
                              placeholder=""
                              size="7rem"
                              />
                            {/* <span className="uppercase text-xs font-black mt-4">
                          {name}
                        </span> */}
                          </label>
                        </div>
                        <h3 className="uppercase font-bold text-sm mt-4">{name}</h3>
                      </div>
                      )
                    )}
                    </div>
              {/* <div className="mt-16 mx-4 font-semibold text-3xl">{item.design}</div> */}
            </>
          )}

          {introStep === "intro-name" && (
            <button
              type="button"
              className={`${
                isEmpty(item.name) ? "bg-opacity-20 bg-black cursor-not-allowed" : "bg-sp-orange"
              } fixed bottom-0 my-16 text-white rounded-full font-semibold text-3xl px-32 py-4`}
              disabled={isEmpty(item.name)}
              onClick={() =>
                setIntroStep(stepOrder[1 + stepOrder.indexOf(introStep)])
              }
            >
              Next
            </button>
          )}

          {introStep === "intro-design" && (
            <button
              type="button"
              className={`${
                isEmpty(item.design) ? "bg-opacity-20 bg-black cursor-not-allowed" : "bg-sp-orange"
              } fixed bottom-0 my-16 text-white rounded-full font-semibold text-3xl px-32 py-4`}
              disabled={isEmpty(item.design)}
              onClick={handleNext}
            >
              Next
            </button>
          )}
          {/* {stepOrder.indexOf(introStep) === stepOrder.length - 1 ? (
            <button
              type="button"
              className="fixed bottom-0 my-16 rounded-full bg-sp-orange text-black text-3xl px-32 py-5"
              onClick={() =>
                navigate(`/collarlab-custom?customStep="custom-font"`)
              }
            >
              Next
            </button>
          ) : (
            <button
              type="button"
              className="fixed bottom-0 my-16 rounded-full bg-sp-orange text-black text-3xl px-32 py-5"
              onClick={() =>
                setIntroStep(stepOrder[1 + stepOrder.indexOf(introStep)])
              }
            >
              Next
            </button>
          )} */}
        </div>
      </>
    </Layout>
  );
}

export default SpidGrid;

export const query = graphql`
  {
    shopifyProduct(shopifyId: {eq: "gid://shopify/Product/6573303758946"}) {
      shopifyId
      title
      options {
        name
        values
      }
      variants {
        title
        shopifyId
      }
      description
    }

    skus: allAirtable(
      filter: {
        table: { eq: "Collarlab SKUs" }
        data: { Active: { eq: true } }
      }
    ) {
      edges {
        node {
          id
          design: data {
            name: Name
            color: Color {
              id
              data {
                name: Name
                primary: Primary_Color
                secondary: Secondary_Color
                text: Text_Color
                background: Background_Color
                icon: Icon_Color
              }
            }
            font: Font {
              id
              data {
                label: Name
                value: Value
              }
            }
            typestyles: Typestyles {
              id
              data {
                label: Name
                value: Value
              }
            }
            icons: Icons {
              id
              data {
                name: Name
                value: Value
                file: File {
                  localFiles {
                    id
                    publicURL
                  }
                }
                tags:Tags
              }
            }
          }
        }
      }
    }
  }
`;
